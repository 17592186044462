<template>
  <div v-element-sizes="{ id: 'window' }">
    <main class="layout-default">
      <SeoTemplatePreset>
        <!-- <NavigationMenu />-->
        <slot />

        <!-- <FooterMain />
        <FooterMedical />

      <WidgetScrollTop />
      <ListingProjectBlockFullscreenOverlay />-->
      </SeoTemplatePreset>
    </main>
  </div>
</template>

<script setup>
const route = useRoute()
</script>
